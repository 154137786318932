@import "../theme/mixins";

$brand: 'moschmosch';
$brandAssets: "/assets/#{$brand}";
:root {
  --brand: $brand;
  --ion-color-main: #212121 ;
  --ion-color-second: #484848;
}

@font-face {
  font-family: AppFont;
  src: url('../assets/font/Poppins-Regular.ttf')
}
@font-face {
  font-family: AppFontBold;
  src: url('../assets/font/Poppins-Bold.ttf')
}
@font-face {
  font-family: AppFontLight;
  src: url('../assets/font/Poppins-Light.ttf')
}
@font-face {
  font-family: SansFont;
  src: url('../assets/font/SourceSansPro-Regular.ttf')
}
@font-face {
  font-family: SansFontBold;
  src: url('../assets/font/SourceSansPro-Bold.ttf')
}
@font-face {
  font-family: SansFontLight;
  src: url('../assets/font/SourceSansPro-Light.ttf')
}
// app-home,
// app-home > ion-content > main {
//   background: var(--ion-color-primary) !important;
// }
